<template>
    <div class="rn-service-area rn-section-gapDoubleTop" data-black-overlay="2">
        <v-container >
            <v-row>
                <v-col cols="12">
                    <v-col cols="12">
                        <div class="section-title mb--20 mb_sm--0">
                            <h2 class="heading-title">Login</h2>
                        </div>
                    </v-col>
                    <div class="form-wrapper mb_sm--0">
                        <ValidationObserver v-slot="{ handleSubmit }">
                            <form @submit.prevent="handleSubmit(onSubmit)">
                                <ValidationProvider name="username"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                    <label>
                                        <input type="text"
                                               v-model="formData.username"
                                               placeholder="Username *" />
                                        <span class="inpur-error">{{ errors[0] }}</span>
                                    </label>
                                </ValidationProvider>

                                <ValidationProvider ref="password" name="password"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                    <label>
                                        <input type="password"
                                               rules="required"
                                               v-model="formData.password"
                                               placeholder="Password *" />
                                        <span class="inpur-error">{{ errors[0] }}</span>
                                    </label>
                                </ValidationProvider>

                                <button class="rn-button-style--2 btn_solid"
                                        type="submit"
                                        value="submit">
                                    Submit
                                </button>
                            </form>
                        </ValidationObserver>
                    </div>
                </v-col>
            </v-row>
            <!-- End .row -->
            
        </v-container>
    </div>

</template>

<script>
  import { ValidationObserver } from "vee-validate";
    import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
    import axios from "axios";
    import qs from 'qs';

  export default {
     components: {
      ValidationObserver,
      ValidationProvider,
    },
     data() {
          return {
            formData: {
              action: "login",
              username: "",
              password: "",
              },
              token: null
          };

        },
        methods: {
            onSubmit() {
                axios({
                    method: 'POST',
                    url: 'https://api.jessplusjosh.com',
                    data: qs.stringify(this.formData)
                })
                    .then(response => {
                    this.token = response.data.token
                    if (response.data.token !== undefined) {
                        localStorage.setItem('jwtToken', response.data.token);
                        this.$router.push('Management');
                    } else {
                        this.$refs.password.applyResult({
                            errors: ["Unable to login, please check your username and password"],
                            valid: false,
                            failedRules: {}
                        });
                    }

                })
                .catch(error => {
                  console.error(error);
            });
            }
        },
  };
</script>

<style lang="scss">
  .feather-menu {
    color: #1d1d24;
  }
</style>
